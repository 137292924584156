<template>
    <form class="ui aftersales form" :class="{loading: saving}" @submit.prevent="saveAfterSales">
        <div class="required field">
            <label>Additional Comments</label>
            <textarea rows="3" v-model.trim="form.comment" required></textarea>
        </div>
        <div class="two fields">
            <div class="required field">
                <label>Sales Total</label>
                <div class="ui left icon input">
                    <input type="number" min="0" step="0.01" v-model.number="form.sales_total" required/>
                    <i class="label icon">RM</i>
                </div>
            </div>
            <div class="field">
                <label>Deposit</label>
                <div class="ui left icon input">
                    <input type="number" min="0" :max="form.sales_total" step="0.01" v-model.number="form.deposit"/>
                    <i class="label icon">RM</i>
                </div>
            </div>
        </div>
        <div class="two fields">
            <div class="field" :class="{required: form.sales_total > 0}">
                <label>
                    Date of Collection <template v-if="form.collection_date">({{form.collection_date|formatdate("D MMM YYYY")}})</template>
                </label>
                <input type="date" :max="(new Date())|formatdate('YYYY-MM-DD')" v-model="form.collection_date" :required="form.sales_total > 0"/>
            </div>
        </div>
        <div class="field" :class="{required: form.sales_total > 0}">
            <label>Mode of Collection</label>
            <div class="inline fields">
                <div class="field" v-for="paymentmode in paymentModes" :key="`option_paymentmode_${paymentmode}`">
                    <div class="ui radio checkbox">
                        <input type="radio" name="mode" tabindex="0" class="hidden" :value="`${paymentmode}`" v-model="form.payment_mode" :required="form.sales_total > 0"/>
                        <label>{{paymentmode.toDescription()}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <label>Instalment</label>
            <div>
                <toggle-button v-model="form.instalment" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
            </div>
        </div>
    </form>
</template>

<script>
import PaymentMode from "@/assets/constants/paymentMode";

import { ToggleButton } from "vue-js-toggle-button";

import { mapActions } from "vuex";

export default {
    name: "after-sales-form",
    props: {
        formdata: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    components: {
        ToggleButton
    },
    data() {
        const blank_form = {
            comment: "",
            sales_total: "",
            deposit: "",
            collection_date: "",
            payment_mode: "",
            instalment: false
        };

        return {
            blank_form,
            form: Object.assign({}, blank_form),
            saving: false,
            paymentModes: PaymentMode,
            toggle_checkbox: {
                color: {
                    checked: "#0cc2da",
                    unchecked: "#cccccc",
                    disabled: "#cccccc"
                },
                width: 60,
                height: 28,
                labels: {
                    checked: "YES",
                    unchecked: "NO"
                }
            }
        };
    },
    mounted() {
        $(this.$el).find(".ui.radio.checkbox").checkbox();
    },
    methods: {
        saveAfterSales() {
            if(this.saving) {
                return;
            }

            let promise = null;

            let form = Object.assign({}, this.form);
            if(!form.payment_mode) {
                form.payment_mode = null;
            }

            if(!form.collection_date) {
                form.collection_date = null;
            }

            if(form.created) {
                promise = this.$http.put(`aftersales/${form.id}`, form);
            } else {
                promise = this.$http.post("aftersales", form);
            }

            this.saving = true;
            promise.then((response) => {
                this.$emit("save:aftersales", response.data);

                this.$toasted.success("After sales record saved successfully.", {
                    duration: 3000
                });
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to save after sales record.", {
                    duration: 3000
                });
            }).finally(() => {
                this.saving = false;
                this.updateOutstandingCount();
            });
        },
        ...mapActions({
            updateOutstandingCount: "outstanding/fetchUpdate"
        })
    },
    watch: {
        formdata: {
            deep: true,
            immediate: true,
            handler() {
                if(!this.isBlankObject(this.formdata)) {
                    this.form = Object.assign({}, this.blank_form, this.formdata);
                }
            }
        },
        saving() {
            this.$emit("change:saving", this.saving);
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.aftersales.form:not(.unstackable) {
    &.side {
        .fields {
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        .two.fields:not(.unstackable) > .field {
            width: 100%!important;
            margin-bottom: 1em;
        }
    }
}

.label.icon {
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: normal !important;
}
</style>